
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}



/*From https://www.cssportal.com/css-ribbon-generator/*/
.ribbon {
  margin: 0;
  padding: 0;
  background: #50a1ff;
  color: white;
  padding: 0.3em 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top: 0;
  margin: 0 -1px;
  /* tweak */
  width: 100%;
  height: 100%;
  background: #50a1ff;
}

.ribbon:before {
  right: 100%;
}

.ribbon:after {
  left: 100%;
}

.title_strong {
  text-decoration: underline;
  font-weight: 600;
}
